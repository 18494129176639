import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import MountainLogo from "./mountain-logo.js"
import * as css from "./header.module.css"
import { Spin as Hamburger } from "react-burgers"
import Logo from "../components/logo"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import useSiteMetadata from "../components/use-site-metadata"
import BlockContent from "@sanity/block-content-to-react"
import { FormattedMessage } from "react-intl"
import UnionJack from "../components/gfx/UnionJack"
import FlagNorway from "../components/gfx/FlagNorway"

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num
}

const Header = ({ hideHeader, langs, langKey, defaultLangKey }) => {
  const [headerStyle, setHeaderStyle] = useState({
    // transition: 'all 120ms ease-in',
    color: hideHeader ? "#fff" : "#000",
  })
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  const [hasFlipped, setHasFlipped] = useState(!hideHeader)

  const { sanitySiteSettings } = useSiteMetadata()

  useScrollPosition(
    ({ currPos }) => {
      const { y } = currPos
      const limit = (window.innerHeight * -1) / 3
      const threshold = 100
      const opacity = (y / (threshold - limit)) * -1 - 0.5
      const shadowOpacity = (y / (threshold - limit)) * -1 - 0.5

      const flipped = y * -1 > window.innerHeight / 1.5

      if (hideHeader) {
        setHasFlipped(flipped)
      }

      setHeaderStyle({
        backgroundColor: hideHeader
          ? `rgba(255, 255, 255, ${clamp(opacity, 0, 1)})`
          : "#fff",
        color: !flipped && hideHeader ? "#fff" : "inherit",
        boxShadow: `2px 0 10px rgba(47, 47, 47, ${clamp(
          hideHeader ? shadowOpacity : 1,
          0,
          0.3
        )})`,
      })
    },
    [headerStyle]
  )
  const activeHamburgerColor = hasFlipped ? "#000" : "#fff"

  const links = langs.map(lang => (
    <li key={lang.langKey}>
      {/* <Link to={lang.link}>
        {lang.langKey === "en" && <UnionJack />}
        {lang.langKey === "nb" && <FlagNorway />}
      </Link> */}
    </li>
  ))

  return (
    <header
      className={hasFlipped ? css.headerFlipped : css.headerUnFlipped}
      style={{ ...headerStyle }}
      data-menu-is-open={menuOpen}
    >
      <Link
        to="/"
        className={css.link}
        style={{
          visibility: hasFlipped || !headerStyle ? "visible" : "hidden",
        }}
      >
        <MountainLogo className={css.logo} />
        <span className={css.title}>{sanitySiteSettings.title[langKey]}</span>
      </Link>
      <div className={css.rightSide}>
        <ul className={css.flags}>{links}</ul>
        <Hamburger
          customProps={{
            title: menuOpen ? (
              <FormattedMessage id="closeMenu" />
            ) : (
              <FormattedMessage id="openMenu" />
            ),
          }}
          onClick={toggleMenu}
          width={18}
          lineHeight={1}
          lineSpacing={4}
          active={menuOpen}
          className={css.hamburger}
          color={menuOpen ? "#000" : activeHamburgerColor}
          padding="0"
        />

        <div className={css.menu}>
          <Logo className={css.bigLogo} langKey={langKey} />
          <ul>
            {sanitySiteSettings.featuredProducts.map(product => {
              return (
                <li key={product.id}>
                  <Link
                    to={`/product/${
                      product.slug[langKey]
                        ? product.slug[langKey].current
                        : product.slug[defaultLangKey].current
                    }`}
                    // className={css.button}
                  >
                    {product.title[langKey] || product.title[defaultLangKey]}
                  </Link>
                </li>
              )
            })}

            {sanitySiteSettings.featuredProperties &&
              sanitySiteSettings.featuredProperties.length > 0 && (
                <h2>Apartments</h2>
              )}

            {sanitySiteSettings.featuredProperties.map(property => {
              const propertyUrl = langKey === "nb" ? "nb" : "properties"
              const currentSlug = property.slug[langKey]
                ? property.slug[langKey].current
                : property.slug[defaultLangKey].current
              return (
                <li key={property.id}>
                  <Link
                    to={`/${propertyUrl}/${currentSlug}`}
                    // className={css.button}
                  >
                    {property.title[langKey] || property.title[defaultLangKey]}
                  </Link>
                </li>
              )
            })}
            {sanitySiteSettings._rawContactInformation && (
              <>
                <h2 style={{ marginTop: "2em" }}>Contact</h2>
                <BlockContent
                  blocks={sanitySiteSettings._rawContactInformation}
                />
              </>
            )}
          </ul>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
