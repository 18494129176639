import { useStaticQuery, graphql } from "gatsby"
export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs
            }
          }
        }
        sanitySiteSettings {
          title {
            en
            nb
          }
          description {
            en
            nb
          }
          _rawOrderingInstructions
          _rawContactInformation
          address
          mainImage {
            asset {
              _id
              url
              gatsbyImageData
            }
          }
          socialImage {
            asset {
              _id
              url
            }
          }
          featuredProperties {
            id
            title {
              en
              nb
            }
            slug {
              en {
                current
              }
              nb {
                current
              }
            }
            images {
              _key
              asset {
                _id
                gatsbyImageData
              }
            }
          }
          featuredProducts {
            id
            title {
              _type
              en
              nb
            }
            description {
              en
              nb
            }
            images {
              _key
              asset {
                _id
                gatsbyImageData
              }
            }
            slug {
              en {
                current
              }
              nb {
                current
              }
            }
            prices {
              _key
              _type
              name {
                en
                nb
              }
              price {
                en
                nb
              }
            }
          }
        }
      }
    `
  )
  return data
}
