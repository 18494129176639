import React from "react";

function MountainLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 11" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.1 1.36l2.83 2.76L16.08 0l4.14 4.11 2.68-2.76L32 10.4l-.62.61-8.47-8.41-2.37 2.45-6 5.96-.62-.61 1.54-1.54-3.84-3.81L9.1 2.58.62 11 0 10.39l9.1-9.03zm3.44 3.37l3.54 3.51 3.53-3.5-3.53-3.52-3.54 3.51z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default MountainLogo;
