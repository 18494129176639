// extracted by mini-css-extract-plugin
export var header = "header-module--header--2ea3u";
export var headerUnFlipped = "header-module--headerUnFlipped--c2Cd6 header-module--header--2ea3u";
export var headerFlipped = "header-module--headerFlipped--zWI1I header-module--header--2ea3u";
export var logo = "header-module--logo--3gIix";
export var link = "header-module--link--3R2_R";
export var title = "header-module--title--2ZuB5";
export var hamburger = "header-module--hamburger--3KYjh";
export var menu = "header-module--menu--19hzF";
export var bigLogo = "header-module--bigLogo--HQuzE index-module--logo--3-YDv";
export var rightSide = "header-module--rightSide--2nhD5";
export var flags = "header-module--flags--3mDfq";