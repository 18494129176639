import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import { Link, useStaticQuery } from "gatsby"
import "./index.css"
import SiteMetadata from "../components/use-site-metadata"
import BlockContent from "@sanity/block-content-to-react"
import MountainLogo from "../components/mountain-logo.js"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { graphql } from "gatsby"
import { IntlProvider } from "react-intl"
import { Helmet } from "react-helmet"
// import "intl"

const Layout = ({ children, hideHeader, location, i18nMessages }) => {
  const { site } = useStaticQuery(
    graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `
  )

  const {
    sanitySiteSettings,
    // siteMetaData
  } = SiteMetadata()

  const url = location && location.pathname
  const { langs, defaultLangKey } = site.siteMetadata.languages
  const langKey = url
    ? getCurrentLangKey(langs, defaultLangKey, url)
    : defaultLangKey
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    item => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, "/"),
    })
  )

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Helmet
        htmlAttributes={{
          lang: langKey,
        }}
      />
      <Header
        hideHeader={hideHeader}
        langs={langsMenu}
        langKey={langKey}
        defaultLangKey={defaultLangKey}
      />
      <div>
        <main>{children}</main>
        <footer className="footer">
          <div>
            <MountainLogo className="mountain-logo" />
            <div style={{ marginTop: "2rem" }}>
              Contact us at{" "}
              <a href="mailto:info@thebronson.no">info@thebronson.no</a>
            </div>
          </div>
          <div>
            <ul>
              {sanitySiteSettings.featuredProducts
                .filter(
                  product =>
                    product &&
                    product.slug &&
                    product.slug[langKey] &&
                    product.slug[langKey].current
                )
                .map(product => {
                  return (
                    <li key={product.id}>
                      <Link to={`/product/${product.slug[langKey].current}`}>
                        {product.title[langKey]}
                      </Link>
                    </li>
                  )
                })}
              {sanitySiteSettings._rawContactInformation && (
                <>
                  <h2 style={{ marginTop: "2em" }}>Contact</h2>
                  <BlockContent
                    blocks={sanitySiteSettings._rawContactInformation}
                  />
                </>
              )}
            </ul>
          </div>
          <address>{sanitySiteSettings.address}</address>
        </footer>
      </div>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
